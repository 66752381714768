{
  /* Move the Heart button inside the flex container */
}
{
  /* <Button
            aria-label="Favoritar"
            variant="outline"
            size="icon"
            className="absolute top-3 left-3 p-2 bg-white dark:bg-gray-700 rounded-full hover:bg-gray-100 dark:hover:bg-gray-600 transition-colors duration-200 shadow-md"
          >
            <HeartIcon className="w-5 h-5 text-red-500" />
          </Button> */
}

import React from "react";
import { Card } from "@/components/ui/card";
import { Badge } from "@/components/ui/badge";
import Link from "next/link";
import MobileCardImage from "./MobileCardImage";


interface Property {
  objectID: string;
  ref?: string;
  property_type?: string;
  neighborhood?: string;
  sale_value?: number;
  rent_value?: number;
  bedrooms?: number;
  suites?: number;
  parking_spaces?: number;
  bathrooms?: number;
  total_area?: number;
  usable_area?: number;
  imagekit?: string;
  title?: string;
  type?: string;
  is_for_sale?: boolean;
  is_for_rent?: boolean;
  photos?: {
    ref: string;
    src: {
      aws: string;
      imagekit: string;
    };
  }[];
}

interface MobileCardProps {
  hit: Property;
  setIsSearchExpanded?: (value: boolean) => void;
  alternativePhotos?: boolean;
}

const MobileCard: React.FC<MobileCardProps> = ({
  hit,
  setIsSearchExpanded,
  alternativePhotos,
}) => {
  // Determine the image URL and blur placeholder
  const imageUrl =
    alternativePhotos && hit.photos && hit.photos.length > 0
      ? hit.photos[0]?.src?.imagekit
      : hit.imagekit || "";

  return (
    <Link
      href={`/imovel/${hit.ref?.startsWith('MB') ? hit.ref : `MB${hit.ref}`}`}
      onClick={() => setIsSearchExpanded && setIsSearchExpanded(false)}
      className="focus:outline-none focus:ring-2 focus:ring-blue-500"
    >
      <Card className="transition-all w-full h-[420px] overflow-hidden rounded-none hover:shadow-xl hover:-translate-y-1 bg-white dark:bg-gray-800 flex flex-col cursor-pointer p-0">
        <div className="relative w-full h-[250px]">
          <MobileCardImage
            imageUrl={imageUrl}
            title={hit?.title}
            lazy={true}
          />
          <Badge className="absolute left-1/2 -translate-x-1/2 bottom-3 font-medium bg-background border border-border text-foreground">
            {hit.ref || "Referência não disponível"}
          </Badge>
        </div>

        <div className="flex-1 p-2 flex flex-col justify-between w-full">
          <div className="space-y-3">
            <h2 className="text-lg font-bold text-navy-900 dark:text-white line-clamp-2 leading-normal">
              {hit.title || "Título indisponível"}
            </h2>
            
            <div className="grid grid-cols-4 gap-1 text-center font-medium text-sm text-navy-700 dark:text-gray-300">
              {hit.bedrooms ? (
                <div className="flex flex-col items-center">
                  <p className="text-muted-foreground">Dorms</p>
                  <span className="font-bold">{hit.bedrooms ?? 0}</span>
                </div>
              ) : null}
              {hit.suites ? (
                <div className="flex flex-col items-center">
                  <p className="text-muted-foreground">Suítes</p>
                  <span className="font-bold">{hit.suites ?? 0}</span>
                </div>
              ) : null}
              {hit.parking_spaces ? (
                <div className="flex flex-col items-center">
                  <p className="text-muted-foreground">Vagas</p>
                  <span className="font-bold">{hit.parking_spaces ?? 0}</span>
                </div>
              ) : null}
              {hit.usable_area ? (
                <div className="flex flex-col items-center">
                  <p className="text-muted-foreground">Área Útil</p>
                  <span className="font-bold">{hit.usable_area ? Number(hit.usable_area).toLocaleString("pt-BR") + " m²" : ""}</span>
                </div>
              ) : null}
            </div>
            <div>
              {/* Sale/Rent information */}
              {hit.is_for_sale ? (
                <span className="flex flex-row items-center gap-2 text-sm">
                  <p className="text-navy-900 dark:text-white">Venda</p>
                  {hit.sale_value !== undefined && (
                    <strong className="text--primary">
                      {hit.sale_value.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      })}
                    </strong>
                  )}
                </span>
              ) : null}
              {hit.is_for_rent ? (
                <span className="flex flex-row items-center gap-2 text-sm">
                  <p className="text-navy-900 dark:text-white">Locação</p>
                  <strong className="text--primary">
                    {hit.rent_value?.toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    })}{" "}
                    / mês
                  </strong>
                </span>
              ) : null}
            </div>
          </div>
        </div>
      </Card>
    </Link>
  );
};

export default MobileCard;
