import React from "react";

function MobileCardImage({ 
  imageUrl = "/placeholder.jpg", 
  title = "Imagem do imóvel não disponível", 
  width = 500, 
  height = 350, 
  lazy = true
}) {
  // Remove the imagekit transformation parameters if they exist
  const baseUrl = imageUrl.split('?')[0];
  
  return (
    <img
      src={`${baseUrl}?tr=w-${width},h-${height},f-auto,q-auto`}
      alt={title}
      width={width}
      height={height}
      loading={lazy ? "lazy" : "eager"}
      className="w-full h-full object-cover"
    />
  );
}
export default MobileCardImage;
