"use client";

import React, { useEffect, useState } from "react";
import { useLayout } from "@/components/LayoutProvider";
import { motion, AnimatePresence } from "framer-motion";
import { liteClient as algoliasearch } from "algoliasearch/lite";
import { useTheme } from "next-themes";
import { useIsDesktop } from "@/lib/useIsDesktop";
import Link from "next/link";
import Animated from "./animated";
import { Hits } from "react-instantsearch";
import DesktopCard from "./global/DesktopCard";
import MobileCard from "./global/MobileCard";
import { InstantSearch as AlgoliaInstantSearch } from "react-instantsearch";
import dynamic from "next/dynamic";
import { Menu } from "./navbar/menu";
import { Hit as AlgoliaHit } from "@algolia/client-search";

interface PropertyHit
  extends AlgoliaHit<{
    active: boolean;
    ref: string;
    property_type: string;
    neighborhood: string;
    sale_value: number;
    rent_value: number;
    bedrooms: number;
    suites: number;
    bathrooms: number;
    total_area: number;
    usable_area: number;
    imagekit: string;
    title: string;
    type: string;
    is_for_sale: boolean;
    is_for_rent: boolean;
  }> {}

// Dynamically import non-critical components
const SearchBox = dynamic(() => import("./navbar/search"), {
  ssr: false,
  loading: () => <div className="w-full h-10" />, // Placeholder while loading
});
const SearchResults = dynamic(() => import("./navbar/search-results"), {
  ssr: false,
});
const Pages = dynamic(() => import("./navbar/pages"), {
  ssr: false,
});
const ToolBar = dynamic(() => import("./navbar/tool-bar"), {
  ssr: false,
});

const searchClient = algoliasearch(
  process.env.NEXT_PUBLIC_ALGOLIA_APPLICATION_ID!,
  process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_ONLY_API_KEY!
);

interface NavbarProps {
  children?: React.ReactNode;
}

const Navbar: React.FC<NavbarProps> = () => {
  const { isSearchExpanded, setIsSearchExpanded } = useLayout();
  const { resolvedTheme: theme } = useTheme();
  const { isDesktop } = useIsDesktop();
  const [indexName, setIndexName] = useState<string>("imoveis");

  // Ensure theme has a default value
  const currentTheme = theme || "light";

  function handleSortChange(selectedSort: string) {
    setIndexName(selectedSort);
  }

  useEffect(() => {
    const escHandler = (e: any) => {
      if (e.key === "Escape") {
        setIsSearchExpanded(false);
      }
    };
    document.addEventListener("keydown", escHandler);

    return () => {
      document.removeEventListener("keydown", escHandler);
    };
  }, []);

  return (
    <>
      <AlgoliaInstantSearch
        searchClient={searchClient}
        indexName={indexName}
        future={{
          preserveSharedStateOnUnmount: true,
        }}
        routing
      >
        <motion.nav className="bg-navbar shadow-sm sticky top-0 z-50 backdrop-filter backdrop-blur-lg py-2 flex h-auto items-center">
          <div className="flex-1 mx-1 px-2 md:px-5">
            <div className="flex items-center justify-between h-16">
              <motion.div
                initial={{ opacity: 0, scale: 0.95 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.95 }}
                transition={{ duration: 0.2 }}
                className="flex items-center"
                key={`navbar-logo-${isSearchExpanded}`}
              >
                <Link href="/" aria-label="Logo MBRAS" className="-mt-6">
                  {isDesktop ? (
                    <img
                      src="https://img.mbras.com.br/mbras/mbras-logo-branca.png?updatedAt=1732857309829,f-auto"
                      className="h-12 mt-2"
                      alt="Logo MBRAS"
                      width="128"
                      height="55"
                    />
                  ) : (
                    <img
                      src="https://img.mbras.com.br/mbras/mbras-logo-branca.png?updatedAt=1732857309829,f-auto"
                      className={`h-10 mt-3 ${isSearchExpanded ? "hidden" : "block"}`}
                      alt="MBRAS Logo"
                      width="99"
                      height="40"
                    />
                  )}
                </Link>
              </motion.div>
              {!isSearchExpanded && (
                <div className="hidden md:block">
                  <div className="ml-36 flex items-center space-x-4">
                    <Link
                      href="/partners"
                      className="text-gray-300 hover:text-white px-3 py-2 rounded-none text-sm font-medium"
                    >
                      MBRAS Partners
                    </Link>
                    <Link
                      href="/club"
                      className="text-gray-300 hover:text-white px-3 py-2 rounded-none text-sm font-medium"
                    >
                      MBRAS Club
                    </Link>
                    <Link
                      href="/brokers"
                      className="text-gray-300 hover:text-white px-3 py-2 rounded-none text-sm font-medium"
                    >
                      MBRAS Brokers
                    </Link>
                  </div>
                </div>
              )}
              <motion.div
                className={`flex-1 flex items-center justify-end ${
                  isSearchExpanded ? "md:w-full" : "md:w-auto"
                }`}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.3 }}
              >
                <motion.div
                  className={`relative mr-2 transition-all duration-300 ${
                    isSearchExpanded ? "w-1/2 md:w-[85%]" : "w-24 md:w-64"
                  }`}
                  initial={{
                    width: isDesktop ? "20rem" : "12rem",
                  }}
                  animate={{
                    width: isDesktop
                      ? isSearchExpanded
                        ? "85%"
                        : "20rem"
                      : isSearchExpanded
                        ? "100%"
                        : "12rem",
                  }}
                  transition={{ duration: 0.3 }}
                >
                  <SearchBox theme={currentTheme} />
                </motion.div>
              </motion.div>

              <Menu setIsSearchExpanded={setIsSearchExpanded} />
            </div>
            <AnimatePresence mode="popLayout">
              {isSearchExpanded ? (
                <Animated>
                  <span className="px-1 md:px-11 flex flex-row items-center justify-around gap-6 my-4 md:my-6">
                    <SearchResults />
                    <ToolBar handleSortChange={handleSortChange} />
                  </span>
                </Animated>
              ) : null}
            </AnimatePresence>
          </div>
        </motion.nav>
        <span>
          <AnimatePresence mode="popLayout">
            {isSearchExpanded ? (
              <Animated className="pt-4 pb-9 pr-9">
                <div className="mx-auto px-2 pb-2 min-h-[50dvh] w-full pt-4">
                  <Hits
                    classNames={{
                      list: "pr-12",
                    }}
                    transformItems={(items: AlgoliaHit<PropertyHit>[]) =>
                      items.map((hit, index) => ({
                        active: hit.active,
                        objectID: hit.objectID,
                        ref: hit.ref,
                        property_type: hit.property_type,
                        neighborhood: hit.neighborhood,
                        sale_value: hit.sale_value,
                        rent_value: hit.rent_value,
                        bedrooms: hit.bedrooms,
                        suites: hit.suites,
                        bathrooms: hit.bathrooms,
                        total_area: hit.total_area,
                        usable_area: hit.usable_area,
                        imagekit: hit.imagekit,
                        title: hit.title,
                        type: hit.type,
                        is_for_sale: hit.is_for_sale,
                        is_for_rent: hit.is_for_rent,
                        __position: index,
                      }))
                    }
                    hitComponent={({ hit }) => {
                      return hit.active ? (
                        isDesktop ? (
                          <DesktopCard
                            hit={hit}
                            setIsSearchExpanded={setIsSearchExpanded}
                          />
                        ) : (
                          <MobileCard
                            hit={hit}
                            setIsSearchExpanded={setIsSearchExpanded}
                          />
                        )
                      ) : (
                        <></>
                      );
                    }}
                  />
                </div>
              </Animated>
            ) : null}
            {isSearchExpanded ? <Pages /> : null}
          </AnimatePresence>
        </span>
      </AlgoliaInstantSearch>
    </>
  );
};

export default Navbar;
