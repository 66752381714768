// DesktopCard.tsx
import React from "react";
import { Card, CardContent, CardFooter } from "@/components/ui/card";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Heart as HeartIcon } from "lucide-react";
import Link from "next/link";
import { cn } from "@/lib/utils";

interface Property {
  objectID: string;
  ref?: string;
  property_type?: string;
  neighborhood?: string;
  sale_value?: number;
  rent_value?: number;
  bedrooms?: number;
  suites?: number;
  bathrooms?: number;
  parking_spaces?: number;
  total_area?: number;
  usable_area?: number;
  imagekit?: string;
  title?: string;
  type?: string;
  is_for_sale?: boolean;
  is_for_rent?: boolean;
  photos?: {
    ref: string;
    src: {
      aws: string;
      imagekit: string;
    };
  }[];
}

interface DesktopCardProps {
  hit: Property;
  setIsSearchExpanded?: (value: boolean) => void;
  alternativePhotos?: boolean;
  className?: string;
  fullWidth?: boolean;
}

const DesktopCard: React.FC<DesktopCardProps> = ({
  hit,
  setIsSearchExpanded,
  alternativePhotos,
  className,
  fullWidth = false,
}) => {
  const formatCurrency = (value: number) => {
    return value.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
  };

  // Moved optimizeImageUrl to a utility file for reuse (optional)
  const optimizeImageUrl = (url: string, width: number) => {
    if (!url) return "/placeholder.jpg"; // Fallback to placeholder image
    const baseUrl = url.split("?")[0];
    return `${baseUrl}?tr=f-auto,q-auto,w-${width}`;
  };

  // Determine the image URL
  const imageUrl =
    alternativePhotos && hit.photos && hit.photos.length > 0
      ? hit.photos?.sort((a: any, b: any) => a.seq - b.seq)[0]?.src?.imagekit
      : hit.imagekit || "/placeholder.jpg";

  return (
    <Link
      href={`/imovel/${hit.ref?.startsWith("MB") ? hit.ref : `MB${hit.ref}`}`}
      onClick={() => setIsSearchExpanded && setIsSearchExpanded(false)}
      className="w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
    >
      <Card
        className={cn(
          "transition-all overflow-hidden border border-border/5 shadow-md hover:shadow-xl hover:-translate-y-0.5 flex flex-col cursor-pointer bg-card w-[400px] h-[390px]",
          className,
          {
            "w-full": fullWidth,
          }
        )}
      >
        <div className="relative h-[220px]">
          <img
            src={optimizeImageUrl(imageUrl, 600)}
            alt={hit.title || "Imagem de propriedade"}
            width={600}
            height={400}
            className="w-full h-full object-cover"
          />

          <div className="absolute inset-x-0 top-0 p-3 flex justify-between items-start">
            <Badge className="bg-white/90 dark:bg-slate-900/90 text-foreground backdrop-blur-sm px-3 py-1 rounded-none font-medium text-sm border-none">
              {hit.neighborhood || "Bairro não disponível"}
            </Badge>

            <Button
              aria-label="Favoritar"
              variant="outline"
              size="icon"
              className="bg-white/90 dark:bg-slate-900/90 backdrop-blur-sm rounded-full hover:bg-white dark:hover:bg-slate-800 transition-colors duration-200 border-none shadow-sm"
            >
              <HeartIcon className="w-4 h-4 text-red-500" />
            </Button>
          </div>

          <Badge
            className="absolute left-3 -bottom-3 rounded-none font-medium text-xs bg-primary text-primary-foreground shadow-md px-3 py-1"
            variant="secondary"
          >
            {hit.ref || "Sem referência"}
          </Badge>
        </div>

        <CardContent className="flex-1 p-4 space-y-3">
          <h2 className="text-sm font-medium text-foreground/90 leading-normal line-clamp-2">
            {hit.title || "Título indisponível"}
          </h2>

          <div className="w-full flex justify-between items-end">
            {hit.is_for_sale && hit.sale_value ? (
              <span className="flex flex-col gap-1 text-sm">
                <p className="text-foreground text-xs">Venda</p>
                <strong className="text-foreground">
                  {hit.sale_value === 1 
                    ? "Sob Consulta" 
                    : formatCurrency(hit.sale_value)}
                </strong>
              </span>
            ) : null}

            {hit.is_for_rent ? (
              <span className="flex flex-col gap-1 text-sm">
                <p className="text-foreground text-xs">Locação</p>
                <strong className="text-foreground">
                  {hit.rent_value === 1 
                    ? "Sob Consulta" 
                    : formatCurrency(hit.rent_value ?? 0)}
                </strong>
              </span>
            ) : null}
          </div>
        </CardContent>

        <CardFooter className="p-4 pt-0">
          <div className="w-full flex items-center justify-between border-t border-border/10 pt-3">
            {hit.bedrooms ? (
              <div className="flex items-center gap-1">
                <span className="text-xs text-muted-foreground">Dorms</span>
                <span className="font-medium text-foreground">{hit.bedrooms}</span>
              </div>
            ) : null}

            {hit.suites ? (
              <div className="flex items-center gap-1">
                <span className="text-xs text-muted-foreground">Suítes</span>
                <span className="font-medium text-foreground">{hit.suites}</span>
              </div>
            ) : null}

            {hit.parking_spaces ? (
              <div className="flex items-center gap-1">
                <span className="text-xs text-muted-foreground">Vagas</span>
                <span className="font-medium text-foreground">{hit.parking_spaces}</span>
              </div>
            ) : null}

            {hit.usable_area ? (
              <div className="flex items-center gap-1">
                <span className="text-xs text-muted-foreground">Área</span>
                <span className="font-medium text-foreground">
                  {`${hit.usable_area.toLocaleString("pt-BR")}m²`}
                </span>
              </div>
            ) : null}
          </div>
        </CardFooter>
      </Card>
    </Link>
  );
};

export default React.memo(DesktopCard);
