"use client";

import React from "react";
import { Mail, Phone, MapPin } from "lucide-react";

interface ContactItemProps {
  icon: React.ReactNode;
  href: string;
  text: string;
  label: string;
}

interface ContactItemSecondaryProps {
  href: string;
  text: string;
  label: string;
}

interface QuickLinkItemProps {
  href: string;
  text: string;
}

interface SocialIconProps {
  href: string;
  label: string;
  icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
}

function ContactItem({ icon, href, text, label }: ContactItemProps) {
  return (
    <li>
      <a
        href={href}
        className="flex items-center text-white hover:text-gray-400"
        aria-label={label}
      >
        {icon}
        <span className="ml-3">{text}</span>
      </a>
    </li>
  );
}

function ContactItemSecondary({ href, text, label }: ContactItemSecondaryProps) {
  return (
    <li>
      <a
        href={href}
        className="flex pl-5 items-center text-white hover:text-gray-400"
        aria-label={label}
      >
       
        <span className="ml-3">{text}</span>
      </a>
    </li>
  );
}

export default function Footer() {
  return (
    <footer className="bg-slate-900 text-white">
      <div className="container mx-auto px-2 py-6">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6 sm:px-6 md:px-8">
          {/* Logo and Address Section */}
          <div>
              <img
              src={"https://img.mbras.com.br/mbras/mbras_MBRAS_horizontal_B_rj6ej0_NOTnT-uPz.jpg?w-128,f-auto,q-75"}
              alt="Logo MBRAS"
              aria-label="Logo MBRAS"
              width="128"
              height="55"
              />

            <div className="not-italic tracking-normal pt-4">
              <a
                href="https://maps.app.goo.gl/8FqHgfei2yJPbiKr7"
                className="hover:text-gray-400"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Abrir endereço no Google Maps"
              >
                <h2 className="text-md mb-2 tracking-wide text-footer">
                  CIDADE JARDIM CORPORATE CENTER
                </h2>
              </a>
            </div>
            {/* Contact Section */}
            <div className="ml-0 mt-4">
              <ul className="space-y-3 hover:text-secondary tracking-wide text-sm">
                <ContactItem
                  icon={<MapPin className="h-5 w-5 text-gray-200" />}
                  href="https://maps.app.goo.gl/8FqHgfei2yJPbiKr7"
                  text="Av. Magalhães de Castro, 4.800 Park Tower – 23° andar"
                  label="Endereço"
                />
                <ContactItemSecondary
                  href="https://maps.app.goo.gl/8FqHgfei2yJPbiKr7"
                  text="Cidade Jardim - São Paulo - SP, 05676-120"
                  label="Endereço"
                />
                <ContactItem
                  icon={<WhatsAppIcon className="h-5 w-5 text-green-600" />}
                  href="https://api.whatsapp.com/send?phone=5511977998888"
                  text="+55 11 97799 8888"
                  label="WhatsApp"
                />
                <ContactItem
                  icon={<Phone className="h-5 w-5 text-gray-300" />}
                  href="tel:+551151856999"
                  text="+55 11 5185 6999"
                  label="Telefone"
                />
                <ContactItem
                  icon={<Mail className="h-5 w-5 text-gray-300" />}
                  href="mailto:mbras@mbras.com.br"
                  text="mbras@mbras.com.br"
                  label="Email"
                />
              </ul>
            </div>
          </div>

          {/* Quick Links and Social Media */}
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 py-9">
            <div>
              <h2 className="text-md font-semibold mb-4 tracking-wide text-footer">
                LINKS RÁPIDOS
              </h2>
              <ul className="space-y-3 text-md tracking-wider">
                <QuickLinkItem href="/anuncie" text="Anuncie seu imóvel" />
                <QuickLinkItem href="/gestao" text="Gestão de Imóveis" />
                <QuickLinkItem href="/sobre" text="Sobre a MBRAS" />
                <QuickLinkItem href="/contato" text="Entre em contato" />
                <QuickLinkItem
                  href="/privacy-policy"
                  text="Política de Privacidade"
                />
                <QuickLinkItem
                  href="https://www.linkedin.com/company/mbras-solu%C3%A7%C3%B5es-imobili%C3%A1rias/jobs/"
                  text="Trabalhe Conosco"
                />
              </ul>
            </div>
            {/* Social Media */}
            <div>
              <h2 className="text-md font-semibold mb-4 tracking-wide text-footer">
                REDES SOCIAIS
              </h2>
              <div className="flex space-x-4 mb-6">
                <SocialIcon
                  href="https://www.facebook.com/mbrasempreendimentos"
                  label="Facebook"
                  icon={FacebookIcon}
                />
                <SocialIcon
                  href="https://www.twitter.com/mbrasimoveis"
                  label="Twitter"
                  icon={TwitterIcon}
                />
                <SocialIcon
                  href="https://www.instagram.com/mbrasempreendimentos"
                  label="Instagram"
                  icon={InstagramIcon}
                />
                <SocialIcon
                  href="https://www.linkedin.com/company/mbras-solu%C3%A7%C3%B5es-imobili%C3%A1rias/"
                  label="LinkedIn"
                  icon={LinkedInIcon}
                />
              </div>
            </div>
          </div>
        </div>

        {/* Footer Bottom */}
        <div className="mt-6 pt-6 border-t border-emerald-700 text-center text-sm">
          <p>
            {new Date().getFullYear()} MBRAS Imobiliária - CRECI-SP 23907-J
          </p>
        </div>
      </div>
    </footer>
  );
}

function QuickLinkItem({ href, text }: QuickLinkItemProps) {
  return (
    <li>
      <a href={href} className="text-gray-300 hover:text-white">
        {text}
      </a>
    </li>
  );
}

function SocialIcon({ href, label, icon: Icon }: SocialIconProps) {
  return (
    <a
      href={href}
      className="text-gray-400 hover:text-gray-300 transition-colors"
      target="_blank"
      rel="noopener noreferrer"
      aria-label={label}
    >
      <Icon width={24} height={24} className="w-6 h-6" />
    </a>
  );
}

// SVG Icons with Explicit Dimensions
type SVGProps = React.SVGProps<SVGSVGElement>;

const FacebookIcon = (props: SVGProps) => (
  <svg
    viewBox="0 0 24 24"
    fill="currentColor"
    width={24}
    height={24}
    {...props}
  >
    <path d="M9.101 23.691v-7.98H6.627v-3.667h2.474v-1.58c0-4.085 1.848-5.978 5.858-5.978.401 0 .955.042 1.468.103a8.68 8.68 0 0 1 1.141.195v3.325a8.623 8.623 0 0 0-.653-.036 26.805 26.805 0 0 0-.733-.009c-.707 0-1.259.096-1.675.309a1.686 1.686 0 0 0-.679.622c-.258.42-.374.995-.374 1.752v1.297h3.919l-.386 2.103-.287 1.564h-3.246v8.245C19.396 23.238 24 18.179 24 12.044c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.628 3.874 10.35 9.101 11.647Z" />
    </svg>
);

const TwitterIcon = (props: SVGProps) => (
  <svg
    viewBox="0 0 24 24"
    fill="currentColor"
    width={24}
    height={24}
    {...props}
  >
    <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z" />
    </svg>
);

const InstagramIcon = (props: SVGProps) => (
  <svg
    viewBox="0 0 24 24"
    fill="currentColor"
    width={24}
    height={24}
    {...props}
  >
    <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zM12 0C8.741 0 8.333.014 7.053.072 2.695.272.273 2.69.073 7.052.014 8.333 0 8.741 0 12c0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98C8.333 23.986 8.741 24 12 24c3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98C15.668.014 15.259 0 12 0zm0 5.838a6.162 6.162 0 100 12.324 6.162 6.162 0 000-12.324zM12 16a4 4 0 110-8 4 4 0 010 8zm6.406-11.845a1.44 1.44 0 100 2.881 1.44 1.44 0 000-2.881z" />
    </svg>
);

const LinkedInIcon = (props: SVGProps) => (
  <svg
    viewBox="0 0 24 24"
    fill="currentColor"
    width={24}
    height={24}
    {...props}
  >
    <path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433a2.062 2.062 0 01-2.063-2.065 2.064 2.064 0 112.063 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z" />
    </svg>
);

const WhatsAppIcon = (props: SVGProps) => (
  <svg
    viewBox="0 0 24 24"
    fill="currentColor"
    width={24}
    height={24}
    {...props}
  >
    <path d="M17.472 14.382c-.297-.149-1.758-.867-2.03-.967-.273-.099-.471-.148-.67.15-.197.297-.767.966-.94 1.164-.173.199-.347.223-.644.075-.297-.15-1.255-.463-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.298-.347.446-.52.149-.174.198-.298.298-.497.099-.198.05-.371-.025-.52-.075-.149-.669-1.612-.916-2.207-.242-.579-.487-.5-.669-.51-.173-.008-.371-.01-.57-.01-.198 0-.52.074-.792.372-.272.297-1.04 1.016-1.04 2.479 0 1.462 1.065 2.875 1.213 3.074.149.198 2.096 3.2 5.077 4.487.709.306 1.262.489 1.694.625.712.227 1.36.195 1.871.118.571-.085 1.758-.719 2.006-1.413.248-.694.248-1.289.173-1.413-.074-.124-.272-.198-.57-.347m-5.421 7.403h-.004a9.87 9.87 0 01-5.031-1.378l-.361-.214-3.741.982.998-3.648-.235-.374a9.86 9.86 0 01-1.51-5.26c.001-5.45 4.436-9.884 9.888-9.884 2.64 0 5.122 1.03 6.988 2.898a9.825 9.825 0 012.893 6.994c-.003 5.45-4.437 9.884-9.885 9.884m8.413-18.297A11.815 11.815 0 0012.05 0C5.495 0 .16 5.335.157 11.892c0 2.096.547 4.142 1.588 5.945L.057 24l6.305-1.654a11.882 11.882 0 005.683 1.448h.005c6.554 0 11.89-5.335 11.893-11.893a11.821 11.821 0 00-3.48-8.413Z" />
    </svg>
);
