import React, { memo } from "react";

const HeroImage = memo(() => {
   const heroSrc = 'https://img.mbras.com.br/site/desktop-hero-9.avif';
   const fallbackSrc = '/images/mbras-hero.jpg';

  return (
    <picture>
      <img
        src={heroSrc}
        alt="Hero Image"
        className="absolute inset-0 w-full h-full object-cover"
        onError={(e) => {
          const target = e.target as HTMLImageElement;
          target.src = fallbackSrc;
        }}
        style={{
          objectFit: 'cover',
          width: '100%',
          height: '100%'
        }}
      />
    </picture>
  );
});

HeroImage.displayName = 'HeroImage';

export default HeroImage;